'use client';

import { useEffect, useState } from 'react';
import {
  ActionIcon,
  Box,
  Checkbox,
  Flex,
  LoadingOverlay,
  Table as MantineTable,
  MultiSelect,
  Text,
  UnstyledButton,
  keys,
  rem,
} from '@mantine/core';
import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
  FunnelSimple,
  MagnifyingGlass,
  Trash,
} from '@phosphor-icons/react/dist/ssr';
import cx from 'classnames';
import { TextInput } from '../TextInput';
import classes from './Table.module.css';
import { RowActions } from './RowActions';
import { RowAction, TableDataRow, TableHeader } from './interfaces';
import { Link } from '../Link';

interface Filters {
  [key: string]: Array<string | number>;
}

interface Props {
  headers: TableHeader[];
  data: TableDataRow[];
  searchable?: boolean;
  selectable?: boolean;
  filters?: string[];
  loading?: boolean;
  totalItems?: any;
  currentPageSet?: any;
  allActionsInMenu?: boolean;
  onRowSelect: (value: any) => void;
  rowActions?: RowAction[];
  onAction: (value: any) => void;
  onSelect?: (ids: string[]) => void;
  nextPage: () => void;
  backPage: () => void;
  firstPage: () => void;
  lastPage: () => void;
}

const PAGE_SIZE = 30 as const;

export const Table = ({
  data,
  headers,
  filters,
  searchable,
  selectable,
  allActionsInMenu,
  loading,
  onAction,
  rowActions,
  onSelect,
  onRowSelect,
  totalItems,
  nextPage,
  backPage,
  lastPage,
  firstPage,
  currentPageSet,
}: Props) => {
  const [tableData, setTableData] = useState(data);
  const [query, setQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [selected, setSelected] = useState<string[]>([]);
  const [currentFilters, setCurrentFilters] = useState<Filters>({});

  const getStartEnd = () => {
    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex =
      startIndex + PAGE_SIZE <= data.length
        ? startIndex + PAGE_SIZE
        : data.length;

    return { start: startIndex, end: endIndex };
  };

  const getStepData = (start: number, end: number) => {
    const slice = [...data].splice(start, end);
    setTableData(slice);
  };

  const setInitialState = (initialData: TableDataRow[]) => {
    setTableData(initialData);
    const { start, end } = getStartEnd();
    const slice = [...initialData].splice(start, end);

    setTableData(slice);
  };

  useEffect(() => {
    if (data) {
      setInitialState(data);
    }
  }, [data]);

  const filterData = (dataToFilter: TableDataRow[], search: string) => {
    const searchQuery = search.toLowerCase().trim();
    return dataToFilter.filter((item) =>
      keys(dataToFilter[0]).some(
        (key) => item[key].value?.toLowerCase().includes(searchQuery),
      ),
    );
  };

  const getFilters = (key: string) => {
    const values = new Set<string>();
    data.forEach((row) => {
      const column = row[key];
      if (column.value) {
        values.add(column.value);
      }
    });

    return [...values];
  };

  const filterDataUsingFilters = (newFilters: Filters) => {
    if (keys(newFilters).length === 0) setTableData(data);

    setCurrentFilters(newFilters);

    const filtered = data.filter((item) => {
      const filteredColumns = keys(newFilters);

      const satisfiesAllFilters = filteredColumns.every((column) =>
        newFilters[column].some(
          (filterValue) => filterValue === item[column].value.toLowerCase(),
        ),
      );

      return satisfiesAllFilters;
    });

    setTableData(filtered);
  };

  const handleFilterChange = (values: string[], columnKey: string) => {
    if (values.length === 0) {
      const newFilters = { ...currentFilters };
      delete newFilters[columnKey];
      setCurrentFilters(newFilters);
      filterDataUsingFilters(newFilters);
    } else {
      const normalizedValues = values.map((v) => v.toLowerCase());
      const newFilters: Filters = {
        ...currentFilters,
        [columnKey]: normalizedValues,
      };
      filterDataUsingFilters(newFilters);
    }
  };

  const handleSearch = (event: { target: { value: string } }) => {
    const { value } = event.target;
    setQuery(value);
    setTableData(filterData(data, value));
  };

  const getTotalPages = () => Math.ceil(totalItems.summary.Total / PAGE_SIZE);

  const handleStep = (nextPage: number) => {
    const totalPages = getTotalPages();

    if (nextPage >= 1 && nextPage <= totalPages) {
      setCurrentPage(nextPage);
      const start = (nextPage - 1) * PAGE_SIZE;
      const end =
        start + PAGE_SIZE <= data.length ? start + PAGE_SIZE : data.length;

      getStepData(start, end);
    }
  };

  const handleCheckToggle = () => {
    if (selected.length !== data.length) {
      const ids = data.map((row) => row._id.value);
      setSelected(ids);

      if (onSelect) onSelect(ids);
    } else {
      setSelected([]);
      if (onSelect) onSelect([]);
    }
  };

  const handleSelect = (id: string) => {
    if (selected.includes(id)) {
      const ids = selected.filter((item) => item !== id);
      setSelected(ids);
      if (onSelect) onSelect(ids);
    } else {
      const ids = [...selected, id];
      setSelected(ids);
      if (onSelect) onSelect(ids);
    }
  };

  const handleNext = () => {
    nextPage();
  };

  const handleBack = () => {
    backPage();
  };

  return (
    <>
      <Flex
        justify="space-between"
        align="center"
        className={classes.filterBox}
      >
        {filters &&
          filters.map((key) => {
            const head = headers.find((h) => h.value === key);
            return (
              <MultiSelect
                variant="unstyled"
                key={key}
                placeholder={'Filtrs'}
                data={getFilters(key)}
                leftSection={
                  <FunnelSimple size={20} style={{ marginBottom: 5 }} />
                }
                onChange={(values) => handleFilterChange(values, key)}
                miw={70}
                ml={rem(10)}
              />
            );
          })}
        {searchable && (
          <TextInput
            leftSection={<MagnifyingGlass />}
            value={query}
            onChange={handleSearch}
            placeholder="Meklēt"
            size="xs"
            mr={rem(10)}
          />
        )}
      </Flex>
      <Box pos="relative">
        <LoadingOverlay visible={loading} />
        <MantineTable
          withRowBorders={false}
          className={classes.tableContainer}
          highlightOnHover={true}
        >
          <MantineTable.Thead h={rem(60)}>
            <MantineTable.Tr className={classes.divider}>
              {selected.length > 0 ? (
                <Flex
                  p={rem(10)}
                  mt={rem(10)}
                  className={classes.selectedHeader}
                >
                  <Checkbox
                    size="xs"
                    radius={3}
                    mr={rem(10)}
                    checked={data.length === selected.length}
                    indeterminate={
                      selected.length > 0 && selected.length !== data.length
                    }
                    onClick={handleCheckToggle}
                  />
                  <Flex gap={rem(10)} align="center">
                    <Text c="#D4D6DD" fz={rem(12)}>
                      {`${selected.length} izvēlēti`}
                    </Text>
                    <UnstyledButton></UnstyledButton>
                  </Flex>
                </Flex>
              ) : (
                <>
                  {selectable && (
                    <MantineTable.Th className={classes.head}>
                      <Checkbox
                        size="xs"
                        radius={3}
                        checked={data.length === selected.length}
                        indeterminate={
                          selected.length > 0 && selected.length !== data.length
                        }
                        onClick={handleCheckToggle}
                      />
                    </MantineTable.Th>
                  )}
                  {headers.map((head) => (
                    <MantineTable.Th key={head.value} className={classes.head}>
                      {head.label}
                    </MantineTable.Th>
                  ))}
                  {rowActions && (
                    <MantineTable.Th className={classes.head}>
                      Darbības
                    </MantineTable.Th>
                  )}
                </>
              )}
            </MantineTable.Tr>
          </MantineTable.Thead>
          <MantineTable.Tbody>
            {tableData.map((row) => (
              <MantineTable.Tr
                key={row._id.value}
                h={rem(70)}
                className={cx({
                  [classes.selected]: selected.includes(row._id.value),
                })}
              >
                {selectable && (
                  <MantineTable.Td>
                    <Checkbox
                      radius={3}
                      size="xs"
                      onClick={() => handleSelect(row._id.value)}
                      checked={selected.includes(row._id.value)}
                    />
                  </MantineTable.Td>
                )}
                {headers.map((column, index) => {
                  const cell = row[column.value];
                  const valueToRender = cell?.display ?? cell?.value;

                  return (
                    <MantineTable.Td
                      key={`r${index}-${column}`}
                      onClick={() => onRowSelect(row._id)}
                    >
                      {cell?.link && (
                        <Link href={row[column.value].link || ''}>
                          {valueToRender}
                        </Link>
                      )}
                      {!cell?.link && valueToRender}
                    </MantineTable.Td>
                  );
                })}
                {rowActions && (
                  <MantineTable.Td>
                    {/* <RowActions
                      allActionsInMenu={allActionsInMenu}
                      row={row}
                      actions={rowActions}
                    /> */}
                    <ActionIcon
                      variant="transparent"
                      onClick={() => onAction(row._id)}
                    >
                      <Trash color="red" />
                    </ActionIcon>
                  </MantineTable.Td>
                )}
              </MantineTable.Tr>
            ))}
          </MantineTable.Tbody>
        </MantineTable>

        <Flex justify="center" mt={rem(40)} gap={rem(20)} align="center">
          <Flex align="center">
            <ActionIcon
              h={rem(20)}
              variant="transparent"
              aria-label="Previous"
              onClick={() => firstPage()}
            >
              <CaretDoubleLeft />
            </ActionIcon>
            <ActionIcon
              h={rem(20)}
              variant="transparent"
              aria-label="Previous"
              onClick={() => handleBack()}
            >
              <CaretLeft />
            </ActionIcon>
            <Text c="#D4D6DD" fz={rem(12)}>
              {currentPageSet} - {totalItems?.summary?.Pages ?? 'N/A'}
            </Text>
            <ActionIcon
              h={rem(20)}
              variant="transparent"
              aria-label="Next"
              onClick={() => handleNext()}
            >
              <CaretRight />
            </ActionIcon>
            <ActionIcon
              h={rem(20)}
              variant="transparent"
              aria-label="Next"
              onClick={() => lastPage()}
            >
              <CaretDoubleRight />
            </ActionIcon>
          </Flex>
        </Flex>
      </Box>
    </>
  );
};
